<div class="d-flex justify-content-center align-items-center flex-column confirm-dialog-title">
  @if (data.icon) {
    <img [src]="data.icon" class="fut-emoji" alt="dialog-icon" />
  }
  @if (data.title) {
    <h1 [ngStyle]="titleStyle?.futStyle || null" [ngClass]="titleStyle?.futClass || ''">{{ data.title | transloco }}</h1>
  }
</div>

<div [ngClass]="{ 'mb-4': !!data.subtitle }" class="py-2 d-flex flex-column confirm-dialog-content" mat-dialog-content>
  @if (data.subtitle) {
    <h3 class="text-muted text-center" [ngStyle]="subtitleStyle?.futStyle || null" [ngClass]="subtitleStyle?.futClass || ''">
      {{ data.subtitle | transloco }}
    </h3>
  }
  @if (data.content) {
    <span>{{ data.content | transloco }}</span>
  }
</div>

<div class="d-flex justify-content-{{ data.buttonsConfig.align }} align-items-center confirm-dialog-action-button">
  @for (button of data.buttonsConfig.buttons || []; track button) {
    <button class="fut-btn fut-btn-{{ button.color }}" (click)="onButtonClick(button)">
      {{ button.text | transloco }}
    </button>
  }
</div>
