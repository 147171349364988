import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { FutStyle, GeneralConfirmDialog, GeneralConfirmDialogButton } from './general-confirm-dialog.model';
import { NgClass, NgStyle } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-general-confirm-dialog',
  templateUrl: './general-confirm-dialog.component.html',
  styleUrls: ['./general-confirm-dialog.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, TranslocoModule, MatDialogContent],
})
export class GeneralConfirmDialogComponent {
  public titleStyle: FutStyle | null;
  public subtitleStyle: FutStyle | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeneralConfirmDialog,
    private dialogRef: MatDialogRef<GeneralConfirmDialogComponent>
  ) {
    this.titleStyle = data.titleStyle || null;
    this.subtitleStyle = data.subtitleStyle || null;
  }

  public onButtonClick(button: GeneralConfirmDialogButton) {
    this.dialogRef.close({ type: button.type, extras: button.extras });
  }
}
