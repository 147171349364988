import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export function createCustomHeader(settings: {
  target_user?: string;
  target_resource?: string;
  event_id?: string;
  custom_params?: Record<string, undefined | string | number | (string | number)[]>;
}): { headers: HttpHeaders } {
  if (settings.custom_params == undefined) settings.custom_params = {};
  if (settings.target_resource) settings.custom_params['Target-Resource'] = settings.target_resource;
  if (settings.event_id) settings.custom_params['Listen-Event'] = settings.event_id;

  return {
    headers: new HttpHeaders({
      'Target-User': settings.target_user || 'self',
      ...settings.custom_params,
    }),
  };
}

export const apiUrl = environment.be_url;
